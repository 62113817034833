import axios from 'axios';
import { apiBase, xAPIHeaders, FormService, IForm } from "../../api";

declare global {
	interface Window {
		currentUser: any
	}
}

export default async function (editor: any, opt: any = {}) {
	const { componentName, offerTraitName, category, pageID } = opt;

  	const blockManager = editor.BlockManager;
	const domComponents = editor.DomComponents;

	const defaultType = domComponents.getType('default');
	const defaultModel = defaultType.model;
	const defaultView = defaultType.view;
	//const formMsgSuccess = "formMsgSuccess";
	//const formMsgError = "formMsgError";

	let formsToRemove: any[] = [];
	let formsToCreate: any[] = [];
	let formsToUpdateOffer: any = {};
	let formsToUpdateHandlers: any = {};

	 // Add the command
	 editor.Commands.add("save-deva-forms", { 
		run: async function(editor: any, sender: any) {

			for (let i = 0; i < formsToRemove.length; i++) {
				const formComponent = formsToRemove[i];
				const isFormExists = !!editor.DomComponents.componentsById[formComponent.ccid];
				const formID = formComponent.attributes.formID;
				if (isFormExists === false && formID !== null) {
					delete formsToUpdateOffer[formID];	// remove form from uprates list
					delete formsToUpdateHandlers[formID];	// remove form from uprates list
					// const formRemoveResponse = await FormService.removeForm(formID);
					// console.log("Form (ID=" + formID + ") removed: " + JSON.stringify(formRemoveResponse.data) );
				}
			}

			// create new added forms on DB
			for (let i = 0; i < formsToCreate.length; i++) {
				const formComponent = formsToCreate[i];
				const isFormExists = !!editor.DomComponents.componentsById[formComponent.ccid];
				if (isFormExists) {
					const createFormResponse = await FormService.createForm(formComponent.getFormInstance());
					formComponent.attributes.formID = createFormResponse.data.id;
					formComponent.attributes.attributes.formID = createFormResponse.data.id;
					console.log("Created form instance on backend formID=" + formComponent.attributes.formID);
				}
			}
			formsToCreate = [];

			// update forms with changed offerID value
			for (let key in formsToUpdateOffer) {
				const formID = +key;
				const updateFormResponse = await FormService.updateForm(formID, { offerID: formsToUpdateOffer[formID] } as IForm);
				console.log("Updated form instance on backend: " + JSON.stringify(updateFormResponse));
			}
			formsToUpdateOffer = {};

			// update forms with changed handlers value
			for (let key in formsToUpdateHandlers) {
				const formID = +key;
				const { handlers } = formsToUpdateHandlers[key].getFormInstance();
				const updateFormResponse = await FormService.updateForm(formID, { handlers } as IForm);
				console.log("Updated form instance on backend: " + JSON.stringify(updateFormResponse));
			}
			formsToUpdateHandlers = {};
		}
	});

	let offers: any[] | null = null;
	let offerFetchPromise: Promise<void> | null = null;

	const getOffers = async () => {
		if (offers == null) {
			if (offerFetchPromise == null) {
				// get offers from backend and fill offers selection for form control
				offerFetchPromise = axios.get(`${apiBase}offers/`, {
					withCredentials: true,
					headers: xAPIHeaders
				}).then(response => {
					offers = response.data.rows.map((offer: any) => ({ value: offer.id, name: offer.title}));
					// enter offers for select control in all form components
					offers !== null && domComponents.addType(componentName, {
						model: {
							defaults: {
								traits: [{
									type: 'select',
									label: "Offer",
									name: offerTraitName,
									options: offers
								}]
							}
						}
					});
				});
			}

			// fetch is already in run - wait until it's end
			await offerFetchPromise;
		}
	}

	blockManager.add(componentName, {
			label: 'Форма регистрации',
			category: category, 
			attributes: {class:''},
			content: `
		<form id="testform" class="deva-form" name="devaRegistrationForm">
			<input type="text" id="name" name="name" placeholder="Имя" required />
			<input type="text" id="phone" name="phone" placeholder="Телефон" required />
			<input type="email" id="email" name="email" placeholder="Электронная почта" required />
			<button type="submit"></button>
		</form>
		`
	});
	

	domComponents.addType(componentName, {
		model: defaultModel.extend({
		  defaults: {
			...defaultModel.prototype.defaults,
			droppable: ':not(form)',
			draggable: ':not(form)',
			traits: [{
				type: 'select',
				label: "Offer",
				name: offerTraitName,
				options: []
			},
			{
				label: 'Редирект на страницу оплаты',
				name: 'redirectToPay',
				type: 'checkbox',
				valueTrue: 'yes', 
				valueFalse: 'no',
			},
			{
				label: 'callbackURL',
				name: 'callbackURL',
				type: 'text',
			},
			{
				label: 'PromoCode',
				name: 'data-promo',
				type: 'text',
			}],
			formID: null,
			offerID: null,
			redirectToPay: 'no',
			pageID: pageID,
			fields: [
				{
				  "name": "email",
				  "required": true
				},
				// {
				//   "name": "phone",
				//   "required": true
				// }
			  ],
			handlers: [
				{
				  "formHandlerTypeID": 1
				},
				{
				  "formHandlerTypeID": 5
				}
			  ], 
			script: function () {
				const serializeForm = (form: any) => {
					let formData: any = {};

					Array.prototype.slice.call(form.elements) // convert form elements to array
						.forEach(function(cur){   // reduce 
							let o: any = {type : cur.type, name : cur.name, value : cur.value}; // get needed keys
							if(['checkbox','radio'].indexOf(cur.type) !==-1){
								o.checked = cur.checked;
							} else if(cur.type === 'select-multiple'){
								o.value=[];
								for(let i=0;i<cur.length;i++){
									o.value.push({
										value : cur.options[i].value,
										selected : cur.options[i].selected
									});
								}
							}
							formData[o.name] = o.value;
					},[]);

					return formData;
				};

				const form = this;

				// ALLOWED SYMBOLS FOR PHONE INPUT
				const phoneInput = form.querySelector("[name='phone']");
				const ALLOWED_PHONE_CHARS_REGEXP = /[0-9\+\(\)]+/;
				if (phoneInput) {
					phoneInput.addEventListener("keypress", (event: any) => {
						if (!ALLOWED_PHONE_CHARS_REGEXP.test(event.key)) {
							event.preventDefault();
						}
					});
				}


				function getCookie(name: string) {
					let matches = document.cookie.match(new RegExp(
						"(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
					));
					return matches ? decodeURIComponent(matches[1]) : undefined;
				}

				
				// FILL SAVED FORM DATA FROM COOKIE
				const SAVED_FORM_DATA_COOKIE_KEY = "deva-saved-form-data";
				const savedFormDataCookie = getCookie(SAVED_FORM_DATA_COOKIE_KEY);
				if (savedFormDataCookie) {
				  const savedFormData = JSON.parse(decodeURIComponent(savedFormDataCookie));
				  for (let key in savedFormData) {
					const input = form.querySelector(`input[name="${key}"]`);
					if (input) {
						input.value = savedFormData[key];
					}
				  }
				}


				const navLocationCookieTitle = 'navlocation';

				// SAVE FORM DATA IN COOKIE ON SUBMIT
				form.addEventListener('submit', (e: any) => {
					e.preventDefault();

					const serializedForm = serializeForm(form);
					const cookieValue = encodeURIComponent(JSON.stringify(serializedForm));
					document.cookie = `${SAVED_FORM_DATA_COOKIE_KEY}=${cookieValue}; path=/; domain=${window.location.hostname}; max-age=31536000`;

					const dbFormID = form.getAttribute("data-form-id") || form.getAttribute("formid");

					const navLocationCookie = getCookie(navLocationCookieTitle);
					let promocode = (form.getAttribute("data-promo")) ? '?promo='+ form.getAttribute("data-promo") : '';

					if (navLocationCookie) {
						serializedForm[navLocationCookieTitle] = navLocationCookie;
					}

					/*
					const name = form.elements.name.value;
					const phone = form.elements.phone.value;
					const email = form.elements.email.value;
					console.log(name, email, phone);
					*/
					

					if ( form.classList.contains('addGSheets') ) {
						try {

							let xhr = new XMLHttpRequest();
							xhr.open("POST", 'https://www.dreamcatchme.ru/integration/googlesheets/formsend.php', false);
							xhr.setRequestHeader('Content-type', 'application/json');
  							xhr.send(JSON.stringify(serializedForm));
							xhr.onload = function() {
								if (xhr.status != 200) { 
									console.log( 'Error on form sheets send: ' + xhr.status);
									return;
								}
								console.log("After form sheets send: " + xhr.response);
							};
								
						} catch (err) {						  
							console.log(err);						  
						}	
					}		

					fetch(process.env.REACT_APP_BACKEND_API_DOMAIN + '/api/v1/' + 'forms/handle/' + dbFormID, { method: 'POST', headers: {"X-API-Key": "quest", "Content-Type": "application/json"}, body: JSON.stringify(serializedForm) })
						.then(r => r.json()).then((response) => {

							if (response.error) {
								alert("Error on form handle: " + response.errorMessage);
								return;
							}

							const handlers = response.data.handlers;
							handlers.forEach((handler: any) => {
								const handlerContent = handler.content;
								switch(handler.type) {
									case "showMessage":
										alert(handlerContent);
										break;

									case "redirect":
										window.top.location.href = handlerContent;
										break;

									case "jsCall":
										if(response.user) window.currentUser = response.user;
										eval(handlerContent);
										break;

									case "redirectToOrderPayment":
										setTimeout(() => {
											window.top.location.href = process.env.REACT_APP_ORDER_PAY_PREFIX + "/" + handlerContent.orderHash + promocode;
										}, 500);
										break;
								}
							});
						});
				});
			},
		  },
	
		  async init() {
				// Check if formID is not defined (new element) - save it for creating Form on DB
				if (this.attributes.formID === null) {
					formsToCreate.push(this);
				}

				if (offers === null) {
					// get offers from backend
					await getOffers();
					// set these offers as options of select for current component
					this.getTrait("offerID").set("options", offers);
				}

				// handle changing offerID in traits
				this.listenTo(this, 'change:attributes:offerID', this.handleOfferChange);


				const redirectTrait = this.getTrait('redirectToPay');
				if (!redirectTrait) {
					// if trait not appeared automatically (it's strange, but it happens) - add it manually
					this.addTrait({
						label: 'Редирект на страницу оплаты',
						name: 'redirectToPay',
						type: 'checkbox',
						valueTrue: 'yes', 
						valueFalse: 'no',
					});
				}

				// add updating form handlers on redirect change
				this.listenTo(this, 'change:attributes:redirectToPay', this.handleRedirectChange);


				const callbackUrlTrait = this.getTrait('callbackURL');
				if (!callbackUrlTrait) {
					// if trait not appeared automatically (it's strange, but it happens) - add it manually
					this.addTrait({
						label: 'callbackURL',
						name: 'callbackURL',
						type: 'text',
					});
				}

				// add updating form handlers on callbackURL change
				this.listenTo(this, 'change:attributes:callbackURL', this.handleCallbackUrlChange);
				
				//this.listenTo(this, 'change:formState', this.updateFormState);
		  },

		  async removed() {
				// Mark form as potentially removed
				formsToRemove.push(this);			
		  },

		  getFormInstance() {
			const firstNameField = this.view.$el.find('input[name="firstname"]');
			if (firstNameField.length > 0) {	// in case form has first & last name fields
				this.attributes.fields.push({
					"name": "firstname",
					"required": true
				});
				this.attributes.fields.push({
					"name": "lastname",
					"required": true
				});
			} else {
				this.attributes.fields.push({
					"name": "name",
					"required": true
				});
			}

			const phoneField = this.view.$el.find('input[name="phone"]');
			if (phoneField.length > 0) {	// in case form has phone field
				this.attributes.fields.push({
					"name": "phone",
					"required": true
				});
			}


			let handlers = this.attributes.handlers;
			const getcourseSignup = this.view.$el.hasClass('getcourse-email-signup');
			if (getcourseSignup) {	// in case form has phone field
				handlers = [
					{
						"formHandlerTypeID": 1
					},
					{
						"formHandlerTypeID": 4,
						"content": `let container = form.parentNode.parentNode; if (container.className.indexOf("careerum-form-tab-item") != -1) { container.classList.add("form-sent") }`
					}
				];

				if (this.getTrait("redirectToPay").get("value") === 'yes') {
					handlers.push({
						"formHandlerTypeID": 5
					});
				}
			}


			const callbackURL = this.getTrait("callbackURL").get("value");
			if (callbackURL && callbackURL != "") {
				handlers = [
					{
						"formHandlerTypeID": 1
					},
					{
						"formHandlerTypeID": 4,
						"content": `window.checkUserFunction(); let container = form.parentNode.parentNode; if (container.className.indexOf("careerum-form-tab-item") != -1) { container.classList.add("form-sent") }`
					},
					{
						"formHandlerTypeID": 6,
						"content": callbackURL
					}
				];
			}

			return {
				pageID: this.attributes.pageID, 
				offerID: this.attributes.offerID, 
				fields: this.attributes.fields, 
				handlers: handlers
			}
		  },

		  async handleOfferChange() {
				this.attributes.offerID = this.attributes.attributes.offerID;
				// store values to update it when the whole page would be saved
				if (this.attributes.formID !== null) {
					formsToUpdateOffer[this.attributes.formID] = this.attributes.offerID;
				}
		  },

		  async handleRedirectChange() {
			// if form saved in DB
			if (this.attributes.formID) {
				// store form in temporary array to update it on any event
				formsToUpdateHandlers[this.attributes.formID] = this;
			}
		  },

		  async handleCallbackUrlChange() {
			// if form saved in DB
			if (this.attributes.formID) {
				// store form in temporary array to update it on any event
				formsToUpdateHandlers[this.attributes.formID] = this;
			}
		  },
	
		  /*
		  updateFormState() {
			var state = this.get('formState');
			switch (state) {
			  case 'success':
				this.showState('success');
				break;
			  case 'error':
				this.showState('error');
				break;
			  default:
				this.showState('normal');
			}
		  },
	
		  showState(state: string | null) {
			var st = state || 'normal';
			var failVis, successVis;
			if (st === 'success') {
			  failVis = 'none';
			  successVis = 'block';
			} else if (st === 'error') {
			  failVis = 'block';
			  successVis = 'none';
			} else {
			  failVis = 'none';
			  successVis = 'none';
			}
			var successModel = this.getStateModel('success');
			var failModel = this.getStateModel('error');
			var successStyle = successModel.getStyle();
			var failStyle = failModel.getStyle();
			successStyle.display = successVis;
			failStyle.display = failVis;
			successModel.setStyle(successStyle);
			failModel.setStyle(failStyle);
		  },
	
		  getStateModel(state: string | null) {
			var st = state || 'success';
			var stateName = 'form-state-' + st;
			var stateModel;
			var comps = this.get('components');
			for (var i = 0; i < comps.length; i++) {
			  var model = comps.models[i];
			  if(model.get('form-state-type') === st) {
				stateModel = model;
				break;
			  }
			}
			if (!stateModel) {
			  var contentStr = formMsgSuccess;
			  if(st === 'error') {
				contentStr = formMsgError;
			  }
			  stateModel = comps.add({
				'form-state-type': st,
				type: 'text',
				removable: false,
				copyable: false,
				draggable: false,
				attributes: {'data-form-state': st},
				content: contentStr,
			  });
			}
			return stateModel;
		  },
		  */
		}, {
		  isComponent(el: HTMLElement) {
			if(el.tagName === 'FORM' && el.classList.contains('deva-form')){
			  return {type: 'deva-registration-form'};
			}
		  },
		}),
	
		view: defaultView.extend({
		  events: {/*
			submit(e: any) {
			  e.preventDefault();
			  alert("123123123!");
			}*/
		  }
		}),
	});


	const nameTrait = {
    name: 'name',
    label: 'Name',
  };
	const valueTrait = {
    name: 'value',
    label: 'Value',
  };

	// SUBMIT BTN
  domComponents.addType('submit button', {
    model: defaultModel.extend({
      defaults: {
        ...defaultModel.prototype.defaults,
        'custom-name': "Input[type=submit]",
        tagName: 'input',
        draggable: 'deva-form, deva-form *',
        droppable: false,
        traits: [
          nameTrait,
          valueTrait,
        ],
      },
    }, {
      isComponent(el: any) {
        if(el.type == 'SUBMIT') {
			if(el.tagName == 'INPUT') {
				return {type: 'input'};
			}
			if(el.tagName == 'BUTTON') {
				return {type: 'button'};
			}
		}
      },
    }),
    view: defaultView,
  });

	// wait until form component will be loaded
	setTimeout(() => { 
		// change form component definition - bacause it takes all html forms
		domComponents.addType("form", {
			isComponent: (el: HTMLElement) => {
				if(el.tagName === 'FORM' && el.classList.contains('deva-form') === false){
					return {type: 'form'};
				}
			}
		});
	}, 1500);

}